<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn small class="text-none" v-bind="attrs" v-on="on" color="red" outlined>Delete</v-btn>
        </template>
        <v-card rounded flat>
            <v-card-title class="text-h6 primary">Delete profile {{ profile.name }}?</v-card-title>
            <v-card-text class="mx-4 mt-8">
                Are you sure you want to delete this profile? This action cannot be taken back.
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn width="50%" rounded color="red" text @click="doDelete">Yes</v-btn>
                <v-btn width="50%" rounded color="green" text @click="close">No</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { ref } from "@vue/composition-api";
import axios from "axios";
import { useRouter } from "vue2-helpers/vue-router";

export default {
    props: ["profile"],
    components: {},
    setup(props) {
        const router = useRouter();

        const dialog = ref(false);
        const errorMessages = ref("");
        const successMessages = ref("");

        function doDelete() {
            axios
                .delete(`/api/v1/profiles/${props.profile.guid}/`)
                .then(() => {
                    router.push("/profiles");
                })
                .catch((error) => {
                    errorMessages.value = error.response.data.detail;
                });
        }

        function close() {
            dialog.value = false;
            errorMessages.value = "";
            successMessages.value = "";
        }

        return {
            dialog,
            errorMessages,
            successMessages,
            doDelete,
            close,
        };
    },
};
</script>
