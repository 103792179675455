<template>
    <v-container>
        <v-row no-gutters>
            <v-col cols="12">
                <Profile :profile="profile" v-if="Object.keys(profile).length" />
                <Profiles height="80vh" v-else />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Profile from "@/components/Profile.vue";
import Profiles from "@/components/Profiles.vue";

import { ref, onMounted, getCurrentInstance } from "@vue/composition-api";
import { useRoute } from "vue2-helpers/vue-router";
import axios from "axios";

export default {
    props: {},
    components: {
        Profile,
        Profiles,
    },
    emits: ["breadcrumb"],
    setup() {
        const route = useRoute();
        const { emit } = getCurrentInstance();
        const profile = ref({});

        onMounted(() => {
            emit("breadcrumb", [
                {
                    text: "Profiles",
                    disabled: false,
                    href: "/profiles",
                },
            ]);

            if (route.params.profile_guid) {
                let profile_guid = route.params.profile_guid;

                axios.get(`/api/v1/profiles/${profile_guid}`).then((response) => {
                    profile.value = response.data;

                    emit("breadcrumb", [
                        {
                            text: "Profiles",
                            disabled: false,
                            href: "/profiles",
                        },
                        {
                            text: profile.value.name,
                            disabled: false,
                            href: `/profiles/${profile_guid}`,
                        },
                    ]);
                });
            }
        });

        return {
            profile,
        };
    },
};
</script>
