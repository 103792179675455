<template>
    <v-container class="px-0">
        <v-row dense>
            <v-col cols="12">
                <MyCard>
                    <template v-slot:header>
                        Profile {{ profile.name }}
                        <v-spacer />
                        <div class="float-right">
                            <v-btn small class="text-none" :disabled="!changed" @click="save" color="green" outlined>Save</v-btn>
                            <v-btn small class="text-none" :disabled="!changed" @click="revert" color="red" outlined>Revert</v-btn>
                            <ProfileDelete :profile="profile" />
                        </div>
                    </template>
                    <!-- profileFilters: <div v-for="f in profileFilters" :key="f.guid">{{f}}</div><br /><br /> -->
                    <!-- selectedFilters: {{ selectedFilters }} <br /><br /> -->
                    <!-- numberOfFiltersPerCategory: {{ numberOfFiltersPerCategory }}<br /><br /> -->
                    <!-- filtersById: {{ filtersById }}<br /><br /> -->
                    <!-- filtersByType: {{ filtersByType }}<br /><br /> -->
                    <!-- filtersInCategory: {{ filtersInCategory }}<br /><br /> -->
                </MyCard>
            </v-col>
            <v-col cols="6">
                <MyCard height="100%" class="overflow-y-auto" max-height="500">
                    <template v-slot:header>Enabled filters ({{ profileFilters.length }})</template>
                    <v-progress-circular v-if="filtersLoading" indeterminate color="primary" />
                    <v-list dense v-else>
                        <!-- default item -->
                        <v-list-item v-if="profileFilters.length == 0" class="text--disabled">
                            <v-list-item-content>
                                <v-list-item-title class="font-italic">(no filters in this profile)</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-for="item in profileFilters" :key="item">
                            <v-list-item-avatar>
                                <v-icon>mdi-crosshairs-gps</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ filtersById[item].name }}</v-list-item-title>
                                <v-list-item-subtitle>IPv4: {{ filtersById[item].ipv4_entries }}, IPv6: {{ filtersById[item].ipv6_entries  }}, last updated: {{ filtersById[item].last_updated | ts_format }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </MyCard>
            </v-col>
            <v-col cols="6">
                <MyCard height="100%">
                    <template v-slot:header>Peers ({{ profile.bgp_peers.length }})</template>
                    <v-list dense rounded>
                        <!-- default item -->
                        <v-list-item v-if="profile.bgp_peers.length == 0" class="text--disabled">
                            <v-list-item-content>
                                <v-list-item-title class="font-italic">(no peers are using this profile)</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-for="item in profile.bgp_peers" :key="item.guid">
                            <v-list-item-avatar>
                                <v-icon left>mdi-router</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title><a :href="`/peers/${item.guid}`">{{ item.ip }}</a></v-list-item-title>
                                <v-list-item-subtitle><a :href="`/peers/${item.guid}`">{{ item.name }}</a></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </MyCard>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="6">
                <MyCard :key="filterCategoryComponent">
                    <template v-slot:header>Filter Category</template>
                    <v-list dense class="transparent">
                        <v-list-item-group @change="changeCategory" v-model="selectedCategory">
                            <v-list-item
                                v-for="(filter_guids, type) in filtersByType"
                                :key="type"
                                :value="type"
                                active-class="primary--text text--accent-4"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <div class="float-left">{{ type }}</div>
                                        <div class="float-right">{{ filter_guids.length }} filters ({{ numberOfFiltersPerCategory[type] }} enabled)</div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </MyCard>
            </v-col>
            <v-col cols="6">
                <MyCard maxHeight="500">
                    <template v-slot:header>
                        Filters
                        <v-spacer />
                        <v-text-field
                            v-model="filterSearch"
                            placeholder="search"
                            hide-details
                            dense
                            single-line
                        />
                    </template>
                    <v-progress-circular v-if="categoryFiltersLoading" indeterminate color="primary" />
                    <v-list
                        dense
                        class="rounded-lg overflow-y-auto"
                        v-else
                        max-height="400"
                        two-line
                    >
                        <v-list-item-group @change="updateProfileFilters" v-model="selectedFilters" multiple>
                            <!-- default item when no filters are available -->
                            <v-list-item dense v-if="selectedCategory < 0" class="text--disabled">
                                <v-list-item-content>
                                    <v-list-item-title class="font-italic">(select filter category)</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <template v-else>
                                <v-list-item
                                    dense
                                    v-for="item in _filtersInCategory"
                                    :key="item"
                                    :value="item"
                                    active-class="primary--text text--accent-4"
                                >
                                    <template v-slot:default="{ active }">
                                        <!-- <v-list-item-content>
                                            <v-list-item-title v-text="getFilterListItemValue(item)" />
                                        </v-list-item-content> -->
                                        <v-list-item-content>
                                            <v-list-item-title>{{ filtersById[item].name }}</v-list-item-title>
                                            <v-list-item-subtitle>IPv4: {{ filtersById[item].ipv4_entries }}, IPv6: {{ filtersById[item].ipv6_entries  }}, last updated: {{ filtersById[item].last_updated | ts_format }}</v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="filtersById[item].description !== null && filtersById[item].description !== ''">{{ filtersById[item].description }} </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox
                                                :input-value="active"
                                                color="primary accent-4"
                                            />
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </MyCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ProfileDelete from "@/components/ProfileDelete.vue";
import MyCard from "@/components/MyCard.vue";

import { ref, onMounted, onUpdated, computed } from "@vue/composition-api";
import axios from "axios";

export default {
    props: ["profile"],
    components: {
        ProfileDelete,
        MyCard,
    },
    setup(props) {
        const changed = ref(false);
        const filtersLoading = ref(true);
        const categoryFiltersLoading = ref(false);

        const selectedCategory = ref(-1);
        const filtersByType = ref({}); // dict of all filter guids available to user, by guid

        const filtersById = ref({});

        const profileFilters = ref([]); // main collection of filter GUIDs of the Profile
        const selectedFilters = ref([]); // selected filters in the currently selected category; model for list-item-group
        const numberOfFiltersPerCategory = ref({}); 

        const filterSearch = ref("");
        const filtersInCategory = ref([]);

        const filterCategoryComponent = ref(0);

        function updateNumberOfFiltersPerCategory() {
            var i;
            for (i in filtersByType.value) {
                numberOfFiltersPerCategory.value[i] = 0;
            }

            for (i in profileFilters.value) {
                numberOfFiltersPerCategory.value[filtersById.value[profileFilters.value[i]].type]++;
            }
        }

        const _filtersInCategory = computed(() => filtersInCategory.value.filter((x) => filtersById.value[x].name.toLowerCase().includes(filterSearch.value.toLowerCase()))); 

        onMounted(() => {
            var i;

            // populate profileFilters with initial GUIDs
            for (i in props.profile.filters) {
                profileFilters.value.push(props.profile.filters[i].guid);
            }

            axios.get("/api/v1/filters").then((response) => {
                for (i in response.data) {
                    filtersById.value[response.data[i].guid] = response.data[i];
                }
                for (i in response.data) {
                    if (response.data[i].type in filtersByType.value) {
                        filtersByType.value[response.data[i].type].push(response.data[i].guid);
                    } else {
                        filtersByType.value[response.data[i].type] = [response.data[i].guid];
                    }
                }
                updateNumberOfFiltersPerCategory();
                filtersLoading.value = false;
            });
        });

        onUpdated(() => {
            categoryFiltersLoading.value = false;
        });

        function updateProfileFilters() {
            // propagates change upstream from selectedFilters to profileFilters

            var i;
            // remove all filters that are in the active category
            var tmp_array = [];
            for (i in profileFilters.value) {
                if (!filtersInCategory.value.includes(profileFilters.value[i])) {
                    tmp_array.push(profileFilters.value[i]);
                }
            }
            profileFilters.value = tmp_array;

            // add selected filters in the active category
            for (i in selectedFilters.value) {
                profileFilters.value.push(selectedFilters.value[i]);
            }
            changed.value = true;
        }

        function save() {
            var postData = {
                guid: props.profile.guid,
                name: props.profile.name,
                new_filters: profileFilters.value,
            };
            
            axios.patch(`/api/v1/profiles/${props.profile.guid}/`, postData).then(() => {
                changed.value = false;
                updateNumberOfFiltersPerCategory();
                filterCategoryComponent.value += 1;
            });
        }

        function revert() {
            var i;
            for (i in props.profile.filters) {
                profileFilters.value.push(props.profile.filters[i].guid);
            }
        }

        function changeCategory() {
            filterSearch.value = "";
            if (typeof selectedCategory.value === "undefined") {
                // delected category
                selectedCategory.value = -1;
                return;
            }
            categoryFiltersLoading.value = true;

            var i;
            // update filtersInCategory for new category
            if (typeof filtersByType.value[selectedCategory.value] !== "undefined") {
                filtersInCategory.value = filtersByType.value[selectedCategory.value];
            } else {
                filtersInCategory.value = [];
            }

            selectedFilters.value = [];
            for (i in profileFilters.value) {
                if (filtersInCategory.value.includes(profileFilters.value[i])) {
                    selectedFilters.value.push(profileFilters.value[i]);
                }
            }
        }

        return {
            changed,
            selectedCategory,
            filtersByType,
            filtersLoading,
            categoryFiltersLoading,
            selectedFilters,
            filtersById,
            filterSearch,
            profileFilters,
            filtersInCategory,
            numberOfFiltersPerCategory,
            updateProfileFilters,
            save,
            revert,
            changeCategory,
            _filtersInCategory,
            filterCategoryComponent,
        };
    },
};
</script>
